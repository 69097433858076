import React, { useState,useEffect } from 'react';
import { Modal, Box, Button, Grid, Typography } from '@mui/material';

const NumericKeypadModal = ({ open, onClose, onAction, ultimotipo, mensaje,setMensaje, nombre }) => {
  const [input, setInput] = useState('');

  const handleButtonClick = (value) => {
    if (input.length < 4) {  // Limita la entrada a 4 dígitos
      setInput((prev) => prev + value);
    }
  };

  const handleClear = () => {
    setInput('');
    setMensaje('')
  };
useEffect(()=>{
  setInput('')
  setMensaje('')
},[open])
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="body2" gutterBottom>
          Último Fichaje ha sido {ultimotipo}
        </Typography>
        <Typography variant="button" gutterBottom>
          {nombre}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Introduce tu PIN (4 digitos)
        </Typography>
        <Typography variant="h4" component="div" gutterBottom>
        {'*'.repeat(input.length)}  {/* Muestra asteriscos según la longitud de la entrada */}
        </Typography>
        <Grid container spacing={2}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number) => (
            <Grid item xs={4} key={number}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleButtonClick(number.toString())}
              >
                {number}
              </Button>
            </Grid>
          ))}
        </Grid>
        <Button variant="outlined" fullWidth sx={{ mt: 2 }} onClick={handleClear}>
          Limpiar
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button 
          variant="contained" 
          color="success" 
          onClick={() => onAction('Entrada', input)}
          // disabled={ultimotipo === 'Entrada'}  // Deshabilitar si ultimotipo es 'entrada'
          >
            Entrada
          </Button>
          <Button 
          variant="contained" 
          color="error" 
          onClick={() => onAction('Salida', input)}
          // disabled={ultimotipo === 'Salida'}  // Deshabilitar si ultimotipo es 'salida'
          >
            Salida
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          {mensaje}
        </Box>
      </Box>
    </Modal>
  );
};

export default NumericKeypadModal;
