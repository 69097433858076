import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Avatar } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NumericKeypadModal from './NumericKeypadModal';
import fichajesService from '../servicios/fichajes.service';
import reloj from './../recursos/avatar icon_4013519.png'
import axios from 'axios';
const UserCard = ({ name, codigo, fotoPerfil, tipo, ultimoFichajeHora, idUsuario, refrescar, setRefrescar }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [mensaje, setMensaje] = useState('')

  const handleCardClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const comprobarimagen = (imagen) => {
    fetch('https://api.fichajedigital.com/usuarios/imagenes/' + imagen + '.jpg')
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });

  }
  const handleAction = (actionType, pin) => {
    setMensaje('')
    console.log(`Acción: ${actionType}, PIN: ${pin}, idUsuario:${idUsuario}`);
    // Aquí puedes agregar la lógica para procesar la entrada/salida
    fichajesService(idUsuario, actionType, pin)
      .then(r => {
        console.log(r)
        setRefrescar(!refrescar)
        setModalOpen(false);
      })
      .catch(err => {
        console.log(err.response.data.message)
        setMensaje(err.response.data.message)
      })


    // setModalOpen(false);
  };
  return (
    <>
      <Card key={idUsuario}
        sx={{
          width: '100%',
          backgroundColor: tipo === 'Entrada' ? 'rgb(0, 255, 0,0.3)' : 'rgb(255, 0, 0,0.3)',
          cursor: 'pointer'
        }}
        onClick={handleCardClick}>  {/* Asegura que la Card ocupe el ancho completo del Grid */}
        {(fotoPerfil) ? (
          // <CardMedia
          //   component="img"
          //   height="90"
          //   image={'https://api.fichajedigital.com/imagenes/'+fotoUrl}
          //   alt={name}
          //   // sx={{ objectFit: 'cover' }} /* Ajusta la imagen para que siempre cubra el área designada */
          // />
          <Avatar src={'https://api.fichajedigital.com/usuarios/imagenes/' + fotoPerfil  + `?${new Date().getTime()}`} sx={{ width: 90, height: 90, m: 'auto', mt: 2 }}>

            {/* <AccountCircleIcon sx={{ width: '100%', height: '100%' }} /> */}
          </Avatar>
        ) : (
          <Avatar src={reloj} sx={{ width: 90, height: 90, m: 'auto', mt: 2 }}>

            {/* <AccountCircleIcon sx={{ width: '100%', height: '100%' }} /> */}
          </Avatar>
        )}
        <CardContent>
          <Typography gutterBottom variant="button">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Código: {codigo}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Tipo: {tipo}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {ultimoFichajeHora}
          </Typography>
        </CardContent>
      </Card>
      <NumericKeypadModal
        open={modalOpen}
        onClose={handleModalClose}
        onAction={handleAction}
        ultimotipo={tipo}
        mensaje={mensaje}
        setMensaje={setMensaje}
        nombre={name}
      />
    </>
  );
};

export default UserCard;

