import React, { useState } from 'react';
import { Modal, Box, Typography, Grid, Button, Backdrop, Fade } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import authService from "../servicios/auth.service";
import { height } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  height:'90%' ,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  borderRadius: '8px',
  textAlign: 'center',
};

const PinInputModal = ({ onPinSubmit }) => {
  const [open, setOpen] = useState(false);
  const [pin, setPin] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleButtonClick = (value) => {
    if (pin.length < 4) {
      setPin((prev) => prev + value);
    }
  };

  const handleClear = () => {
    setPin('');
  };

  const handleDelete = () => {
    setPin(pin.slice(0, -1));
  };

  const handleSubmit = () => {
    handleClose();
    authService.comprobarPin(pin)
    .then (r=>{
        console.log(r)
        if (r){
            authService.logout()
            if (onPinSubmit) {
                onPinSubmit(pin);
              }
        }
    })
    .catch(err=>{
        console.log(err)
    })

  };
  const renderPinDisplay = () => {
    // Crea un array de 4 guiones bajos
    const display = Array(4).fill('_');
    // Reemplaza los guiones bajos con asteriscos en función de la longitud del PIN introducido
    for (let i = 0; i < pin.length; i++) {
      display[i] = '*';
    }
    return display.join(' ');
  };

  return (
    <>
      <Button onClick={handleOpen} variant="contained" endIcon={<LogoutIcon />}>
        Salir
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography variant="h6" component="h3" gutterBottom>
              Introduce tu PIN
            </Typography>
            <Typography variant="h4" gutterBottom>
            {renderPinDisplay()}
            </Typography>
            <Grid container spacing={2}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 'C', 0, '←'].map((value) => (
                <Grid item xs={4} sm={4} key={value}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      if (value === 'C') {
                        handleClear();
                      } else if (value === '←') {
                        handleDelete();
                      } else {
                        handleButtonClick(value.toString());
                      }
                    }}
                    sx={{
                        height: { xs: 10, sm: 40, xl:60 },  // Ajusta la altura de los botones según el tamaño de la pantalla
                        fontSize: { xs: 12, sm: 18, xl:24 },  // Ajusta el tamaño del texto según el tamaño de la pantalla
                      }}
                  >
                    {value}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
              >
                Enviar
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default PinInputModal;

