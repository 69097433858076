import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import axiosApi from './../servicios/axios.service';
import UserCard from './UserCard';

const SedeContent = ({ sedeId }) => {
  const [usuarios, setUsuarios] = useState([]);
  const [refrescar, setRefrescar] = useState(false);

  useEffect(() => {
    const fetchSedeData = async () => {
      try {
        const response = await axiosApi.axiosApi.get(`https://api.fichajedigital.com/api/panel/empleadossede?id_sede=${sedeId}`);
        const data = response.data;
        console.log(data)
        setUsuarios(data);
      } catch (error) {
        console.error('Error al obtener los datos de la sede:', error);
      }
    };

    fetchSedeData();
  }, [sedeId, refrescar]);

  return (
    <Grid container spacing={2} alignItems="stretch"> {/* Asegura que todos los Grid items se estiren */}
      {usuarios.map((user) => (
        <Grid
          item
          xs={6}
          sm={4}
          md={4}
          lg={3}
          xl={2}
          key={user.idUsuario}
          style={{ display: 'flex' }} // Hace que el Grid item ocupe toda la altura disponible
        >
          <UserCard
            refrescar={refrescar}
            setRefrescar={setRefrescar}
            idUsuario={user.idUsuario}
            name={user.name}
            codigo={user.codigo}
            tipo={user.tipo}
            ultimoFichajeHora={user.ultimoFichajeHora}
            fotoPerfil={user.fotoPerfil}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default SedeContent;

