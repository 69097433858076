import axios from "axios";
import AxiosApi from './axios.service'
const API_URL = "https://api.fichajedigital.com/api/";
const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
  'Access-Control-Allow-Origin': '*',
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  'Accept': 'application/json',
}

const login = async (usuario, password) => {

  return axios
    .post(API_URL+'auth/login',
   
    {
      
      "email": usuario,
      "password": password,
    },{
      headers:headers
    })
    .then((response) => {
      console.log("response", response.data)
    //   {
    //     "status": true,
    //     "message": "User Logged In Successfully",
    //     "token": "1086|sGebl3TqvL6dGkGM3AaDN1VS1j9PBBkLKeqD1WBM81865bc8",
    //     "rol": "Panel",
    //     "nombre": "Aznar, Juan Carlos"
    // }


      if (response.data.token && response.data.rol==='Panel') {
        var token = (response.data.token);
        var nombre=response.data.nombre
        var rol =response.data.rol
        var status =  response.data.status
        localStorage.setItem("usuario", nombre);
        localStorage.setItem("token", token);
        localStorage.setItem("rol", rol);
        localStorage.setItem("status",status)
      } else {
        // window.alert ("El usuario no tiene permiso para acceder al Panel")
        var error={}
        error.response={}
        error.response.data={}
        error.response.data.message="El usuario no tiene permiso para acceder al Panel"
        

        return (error)
        logout()
      }

      return (response.data);

    });
};

const logout = () => {
  localStorage.removeItem("usuario");
  localStorage.removeItem("token");
  localStorage.removeItem("rol");
  localStorage.removeItem("status");
  window.location.reload()
};
const nombreusuario = ()=>{
  const usuario =localStorage.getItem("usuario")
  if (!usuario) {
    return ''
  } else {
    return usuario
  }
}

const getCurrentUser = () => {
  if (localStorage.getItem("usuario") === null) {
    return ({
      "id": "00000",
      "nombre": "Perico Palotes",
      "rol": "user"
    })
  }
  // console.log("getcurrentuser", localStorage.getItem("usuario"))
  if (localStorage.getItem("usuario")) {
    console.log('AuthService.getCurrentUser ',
        localStorage.getItem("usuario"),
        JSON.parse(localStorage.getItem("observaciones"))
    );
    return ([
      localStorage.getItem("usuario"),JSON.parse(localStorage.getItem('observaciones'))]);
  } else {
    return ({
      "id": "000000",
      "nombre": "Perico Palotes",
      "rol": "user"
    })

  };
}
// const tiempoHastaExpirar = ()=>{
//   // console.log((jwt_decode(localStorage.getItem("token")).exp - Date.now()/1000)*1000)
//   return ((jwt_decode(localStorage.getItem("token")).exp - Date.now()/1000)*1000)
// }
const comprobarToken = () => {
  // console.log('Llamada a comprobarToken')
  if (localStorage.getItem("token")) {

    // console.log('expiration ' + jwt_decode(localStorage.getItem("token")).exp + ' | ' + Date.now() / 1000)
    // console.log('Token expirado=>'+jwt_decode(localStorage.getItem("token")).exp < Date.now() / 1000)
    // return (jwt_decode(localStorage.getItem("token")).exp < Date.now() / 1000)
    return false
  } else {
    // No hay token
    console.log('No hay Token')
    return true
  }

}


const comprobarRol = (nivel) => {
  const niveles =[
    ["*", "admin"],
    ["*", "admin", "adjuntocontrol"],
    ["*", "admin", "adjuntocontrol", "encargado","ventas"]
  ]
  try{
    const rol = JSON.parse(localStorage.getItem('observaciones')).rol;
    return(niveles[nivel].includes(rol) ? true :false)
  } catch
  {
    return false
  }
  
  const usuario = JSON.parse(localStorage.getItem('usuario'))

  console.log('rol del usuario' + usuario)
  return (usuario.rol)
}
const getObservaciones = async ()=>{
  let url = 'https://empleados.diferente.es/api/observacionesempleado';
  AxiosApi.axiosApi
    .get(url)
    .then((response) => {

        console.log(response);
        localStorage.setItem("observaciones", JSON.stringify(response.data.user));

      
    })
    .catch((error) => {
       
        console.log(error);
    });
}
const comprobarPin = async (pin)=>{
  return new Promise((resolve,reject)=>{
    let data ={
      pin:pin,
  
  }
    AxiosApi.axiosApi
      .post(API_URL+'panel/comprobarpin',data)
      .then((response) => {
  
          console.log(response);
          if (response.data){
            resolve(response.data)
          } else {
            reject(response.data)
          }
          
          
  
        
      })
      .catch((error) => {
         
          console.log(error);
          reject(error)
      });

  })

}
const exportar= {
  // register,
  login,
  logout,
  comprobarToken,
  getCurrentUser,
  getObservaciones,
  comprobarRol,
  comprobarPin

};
export default exportar