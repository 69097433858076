import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import axiosApi from './../servicios/axios.service'
import SedeContent from './SedeContent';  // Importa el componente SedeContent
const SedesTabs = () => {
  const [sedes, setSedes] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    // Aquí realizamos la consulta a la API para obtener las sedes
    
    const fetchSedes = async () => {
      try {
        const response = await axiosApi.axiosApi
        .get('https://api.fichajedigital.com/api/panel/listadosedes')
        console.log(response)
        const data = response.data
        data.map((item, index)=>{
          if (item.nombre.startsWith("ZZZ")) {
            item.nombre= item.nombre.slice(3)
          }
        })
        setSedes(data);

      } catch (error) {
        console.error('Error al obtener las sedes:', error);
      }
    };

    fetchSedes();
  }, []);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={selectedTab} onChange={handleChange} aria-label="tabs de sedes">
        {sedes.map((sede, index) => (
          <Tab key={sede.id} label={sede.nombre} />
        ))
  
        
        }
      </Tabs>
      <Box sx={{ p: 3 }}>
        {sedes.map((sede, index) => (
          <div
            role="tabpanel"
            hidden={selectedTab !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            key={sede.id}
          >
            {selectedTab === index && (
              <SedeContent sedeId={sede.id} />  /* Renderiza el componente SedeContent */
            )}
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default SedesTabs;
