
import authService from "../servicios/auth.service";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import React, { useState, useEffect } from "react";
import PinInputModal from "../componentes/PinInputModal";
import SedesTabs from "../componentes/SedesTabs";
import { useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
function About() {
  const theme = useTheme();  // Accede al tema para obtener los colores
  const handlePinSubmit = (pin) => {
    console.log("PIN introducido:", pin);
    // Puedes agregar lógica adicional aquí si es necesario
  };
  useEffect(() => {
    document.title = "Tu panel de Fichaje";
  }, []);
  const salirPanel = () => {
    console.log('salirPanel')
    // mostrar teclado
    authService.comprobarPin('1920')

  }
  return (
    <>

      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { backgroundImage: 'none', backgroundColor: theme.palette.secondary.background },
        }}
      />
      <Box 
        sx={{ 
          bgcolor: theme.palette.secondary.background,  // Color plano aplicado aquí
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'top',
          alignItems: 'flex-start'
        }}
      >
      <Container sx={{
        m: 3 ,
        maxWidth:{xs:"xs", xl:"xl"}
      }} >
      <PinInputModal onPinSubmit={handlePinSubmit} />
        {/* <Box sx={{ bgcolor: 'rgba(161, 177, 53, 0.15)',m:3}} > */}
          <SedesTabs/>
        {/* </Box> */}

      </Container>
      </Box>




    </>
  );
}
export default About;
